var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"cancel-variant":"outline-secondary","ok-title":_vm.$t('Сохранить'),"no-close-on-backdrop":"","cancel-title":_vm.$t('Отмена'),"centered":"","title":_vm.title},on:{"ok":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2"},[_c('b-form-group',{attrs:{"label":_vm.$t('Именование(uz)'),"label-for":"name_uz"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Именование(uz)'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name_uz","state":errors.length > 3 ? false:null,"name":"name","placeholder":""},model:{value:(_vm.form.name_uz),callback:function ($$v) {_vm.$set(_vm.form, "name_uz", $$v)},expression:"form.name_uz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Именование(ru)'),"label-for":"name_ru"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Именование(ru)'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name_ru","state":errors.length > 0 ? false:null,"name":"name","placeholder":""},model:{value:(_vm.form.name_ru),callback:function ($$v) {_vm.$set(_vm.form, "name_ru", $$v)},expression:"form.name_ru"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Ключевое слово'),"label-for":"code"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Ключевое слово'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code","state":errors.length > 3 ? false:null,"name":"name","disabled":!!_vm.form.id,"placeholder":""},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Значение'),"label-for":"value"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Значение'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"value","state":errors.length > 3 ? false:null,"name":"name","placeholder":""},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }